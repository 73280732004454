* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
}

body {
	font-family: "Manrope", sans-serif;
	height: calc(var(--vh, vh) * 100);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	background-color: rgb(250, 250, 250);
	color: rgb(50, 50, 50);
}

p {
	font-size: 16px;
	font-weight: 100;
}

a {
	margin-top: 10px;
	cursor: pointer;
	text-decoration: none;
}

#styledLink {
	color: #0faf89;
	margin-top: 10px;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	display: inline-block;
}

a:hover {
	text-decoration: underline;
}

a:visited {
	color: rgb(50, 50, 50);
}

h1 {
	font-size: 28px;
	display: block;
	font-weight: 100;
}

h2 {
	font-size: 24px;
}

h3 {
	font-size: 18px;
	font-weight: 100;
	margin-left: 10px;
}

h3:hover {
	text-decoration: underline !important;
}

h5 {
	font-size: 18px;
	margin-top: 10px;
}

noscript {
	margin: auto;
	text-align: center;
	background-color: #ffa69e;
	padding: 30px;
}

::selection {
	color: white;
	background: #0faf89; /* WebKit/Blink Browsers */
}
::-moz-selection {
	color: white;
	background: #0faf89; /* Gecko Browsers */
}

/* nur für cube funktion */

.scene {
	width: 100px;
	height: 100px;
	perspective: 800px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* padding-top: 20px; hier das ist der abstand nach oben vom content */
}

.cube {
	width: 100px;
	height: 100px;
	position: relative;
	transform-style: preserve-3d;
	transition: transform 1s;
}

.cube.show-front {
	transform: translateZ(-40vw) rotateY(0deg)
		translateY(calc(var(--vh, vh) * 10));
}
.cube.show-back {
	transform: translateZ(-40vw) rotateY(180deg)
		translateY(calc(var(--vh, vh) * -30));
}
.cube.show-right {
	transform: translateZ(-400px) rotateY(-90deg)
		translateY(calc(var(--vh, vh) * -70));
}
.cube.show-left {
	transform: translateZ(-20vw) rotateY(90deg);
}
.cube.show-top {
	transform: translateZ(-60vw) rotateX(-90deg);
}

.face {
	position: absolute;
	width: 2000px;
	height: 80px;
	/* outline: solid 1px violet; */

	display: flex;
	flex-direction: column;
}

.front {
	transform: rotateY(0deg) translateZ(40vw);
}

.back {
	transform: rotateY(180deg) translateZ(40vw)
		translateY(calc(var(--vh, vh) * 40));
}

.right {
	transform: rotateY(90deg) translateZ(400px)
		translateY(calc(var(--vh, vh) * 80));
}

.left {
	transform: rotateY(-90deg) translateZ(20vw)
		translateY(calc(var(--vh, vh) * 10));
}

.top {
	transform: rotateX(90deg) translateZ(60vw)
		translateY(calc(var(--vh, vh) * 10));
}

/* header */

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	position: absolute;
	width: 100%;
	z-index: 20000000;
	margin-top: 20px;
	padding: 0px 40px;
}

.nav {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.nav > .fullsize {
	display: block;
}

.logo {
}

.hamburger {
	padding: 15px;
	display: none;
}

.hamburger > div {
	background-position-x: 25px;
	width: 25px;
	height: 24px;
	background-image: url("./images/Hamburger.png");
	object-fit: contain;
	background-size: cover;
}

.hamburger.open > div {
	background-position-x: 0px;
}

@media screen and (-webkit-min-device-pixel-ratio: 2),
	screen and (min-resolution: 192dpi),
	screen and (min-resolution: 2dppx) {
	.hamburger > div {
		background-image: url("./images/Hamburger@2x.png");
	}
}

.hamburgercontainer {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	height: calc(var(--vh, vh) * 100);
	z-index: 2000000;

	pointer-events: none;
}

.hamburgermenu {
	overflow: hidden;

	height: 1px;
	transition: height 0.6s ease;

	pointer-events: auto;
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	background-color: rgba(250, 250, 250, 0.8);

	top: -1px;

	display: flex;
	/* align-items: center; */
	justify-content: center;
}

.hamburgermenu.open {
	height: calc(var(--vh, vh) * 100);

	top: 0px;

	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
}

.floorplan {
	display: block;

	position: relative;
	right: calc(var(--vh, vh) * 0.5);

	opacity: 0;

	transition: opacity 0.5s ease-in;
}

.floorplan.open {
	opacity: 1;
}

.clickable {
	cursor: pointer;
}

/* alle seiten */

.content1 {
	opacity: 0;
	transition: opacity 0.45s ease-in-out;
}
.content2 {
	opacity: 0;
	transition: opacity 0.45s ease-in-out;
}
.content3 {
	opacity: 0;
	transition: opacity 0.45s ease-in-out;
}
.content4 {
	opacity: 0;
	transition: opacity 0.45s ease-in-out;
}
.content5 {
	opacity: 0;
	transition: opacity 0.45s ease-in-out;
}

.start {
	background-color: #ffa69e;
	padding: 20% 10%;
	width: 70%;
	margin: auto;
	align-self: center;
}

.project {
	background-color: #aed9e0;
	padding: 20px;
	width: 40%;
}

.one {
	position: absolute;
}

.two {
	position: absolute;
	bottom: 0%;
	left: 45%;
}

@media (max-width: 1200px) {
	.about.two {
		width: 70%;
		left: 30%;
	}
}

.three {
	position: absolute;
	top: 5%;
	right: 0%;
}

.contact {
	background-color: #faf3dd;
	padding: 25% 5%;
	align-self: center;
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.about {
	background-color: #b8f2e6;
	padding: 20px;
	width: 40%;
}

.language {
	color: white;
	background-color: #5e6472;
	padding: 20px;
	width: 300px;
	position: absolute;
	top: 30%;
	right: 10%;
}

.content2 > img {
	max-width: 100%;
}

ul {
	margin: 10px 0 0 10px;
}

li {
	font-size: 16px;
	font-weight: 100;
}

/* Scrollcontainer */

#fakeProjects {
	height: calc(var(--vh, vh) * 100);
	position: absolute;
	top: 0;
	padding-top: 75px;
	z-index: 10000;
	overflow: scroll;
	display: none;
}

#fakeProjects > div {
	padding: 20px;
	background-color: #aed9e0;
	width: 90%;
	margin: 20px 5%;
}

.fakeContent1 {
	transition: opacity 0.45s ease-in-out;
}

#fakeAbout {
	height: calc(var(--vh, vh) * 100);
	position: absolute;
	top: 0;
	padding-top: 75px;
	z-index: 10000;
	overflow: scroll;
	display: none;
}

#fakeAbout > div {
	padding: 20px;
	background-color: #b8f2e6;
	width: 90%;
	margin: 20px 5%;
}

.fakeContent2 {
	transition: opacity 0.45s ease-in-out;
}

@media (max-width: 800px) {
	.one {
		margin-top: 20px;
	}

	h1 {
		font-size: 24px;
	}

	.header {
		padding: 0px 20px;
	}

	.nav > .fullsize {
		display: none;
	}

	.hamburger {
		display: block;
	}
	/* seiten, nur anfassen wenn nötig;)*/

	.front {
		transform: translateZ(40vw) translateY(calc(var(--vh, vh) * -10));
	}

	.back {
		transform: rotateY(180deg) translateZ(40vw)
			translateY(calc(var(--vh, vh) * 20));
	}

	.right {
		transform: rotateY(90deg) translateZ(400px)
			translateY(calc(var(--vh, vh) * 70));
	}

	.left {
		transform: rotateY(-90deg) translateZ(60vw)
			translateY(calc(var(--vh, vh) * 20));
	}

	.cube.show-left {
		transform: translateZ(-60vw) rotateY(90deg)
			translateY(calc(var(--vh, vh) * -20));
	}

	.top {
		transform: rotateX(90deg) translateZ(60vw)
			translateY(calc(var(--vh, vh) * 0));
	}

	.face {
		overflow: scroll;
		padding-top: 75px;
	}

	/* ab hier darf gepfuscht werden */

	.start {
		width: 90%;
	}

	.project {
		width: 90%;
		align-self: center;
		margin-bottom: 20px;
	}

	.about {
		padding: 20px;
		width: 90%;
		margin-bottom: 20px;
		align-self: center;
	}

	.about.two {
		padding: 20px;
		width: 90%;
		margin-bottom: 20px;
		align-self: center;
		position: relative;
		left: 0%;
	}

	.one {
		position: relative;
	}

	.two {
		position: relative;
		left: 0%;
	}

	.three {
		position: relative;
		top: 0%;
	}

	.contact {
		width: 90%;
		padding: 30% 5%;
	}

	.project > img {
		max-width: 50%;
		align-self: center;
	}

	.language {
		width: 300px;
		position: absolute;
		top: 40%;
		right: calc(50% - 150px);
	}
}

/* Impressum */

.imprintContainer {
	margin: 40px;
	margin-bottom: 40px;
	display: flex;
	flex-direction: column;
}

.backLink {
	margin: 10px 0px;
	color: #ffa69e;
}

.backLink:visited {
	margin-top: 10px;
	color: #ffa69e;
}

.sourceLink {
	color: #ffa69e;
}

.sourceLink:visited {
	color: #ffa69e;
}
